import React from "react";
import {
  TextField,
  makeStyles,
  FileInput,
  ResourceCreate,
  useNavigate
} from "goodmap-core";
import { useGoodmapContext } from "goodmap-core";
import { useForm } from "react-hook-form";
import PageContent from "_components/PageContent";

const useStyles = makeStyles(() => ({
  root: {
    padding: 8
  },
  field: {
    marginRight: 16
  }
}));

const CadastreImportCreate = () => {
  const classes = useStyles();
  const config = useGoodmapContext();
  const { handleSubmit, register, errors, setValue } = useForm();
  const navigate = useNavigate()

  return (
    <PageContent>
      <ResourceCreate
        api={config.API_URL}
        onListClick={() => navigate("/cadastreImports")}
        onSuccess={() => navigate("/cadastreImports")}
        resource="CadastreImport"
        handleSubmit={handleSubmit}
      >
        <TextField
          required
          margin="normal"
          InputLabelProps={{ shrink: true }}
          className={classes.field}
          name="date"
          type="date"
          error={errors.name}
          label="Dátum"
          inputRef={register({ required: "Povinné pole" })}
          variant="outlined"
        />
        <input type="hidden" name="file_id" ref={register()} />
        <FileInput
          onUploadSuccess={file => setValue("file_id", file.id)}
          url="/cadastre/upload"
        />
      </ResourceCreate>
    </PageContent>
  );
};

export default CadastreImportCreate;
