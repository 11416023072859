export default {
    API_URL: "https://api.goodmap.sk/api",
    API_BASE: "https://api.goodmap.sk",
    env: "development",
    primaryLogo: "./assets/img/goodmap-logo.png",
    secondaryLogo: "./assets/img/skymove-logo.png",
    bgImage: "./assets/img/goodmap-bg-image.jpg",
    defaultTenant: "demo",
    IAM: {
        base: {
            url: 'https://auth.goodmap.sk/auth/',
            realm: 'goodmap',
            clientId: 'goodmap'
        },
        initOptions: {
            checkLoginIframe: false, 
            onLoad: 'login-required'
        }
    }
}