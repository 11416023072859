import React from "react";
import { makeStyles, FileInput, ResourceCreate, useParams, useNavigate } from "goodmap-core";
import { useGoodmapContext } from "goodmap-core";
import { useForm } from "react-hook-form";
import PageContent from "_components/PageContent";

const useStyles = makeStyles(() => ({
  root: {
    padding: 8
  },
  field: {
    marginRight: 16
  }
}));

const UserCreate = () => {
  const classes = useStyles();
  const { handleSubmit, register, setValue, errors } = useForm();
  const config = useGoodmapContext();
  const navigate = useNavigate();

  return (
    <PageContent>
    <ResourceCreate
      api={config.API_URL}
      onListClick={() => navigate("/surveySketches")}
      onSuccess={() => navigate("/surveySketches")}
      resource="surveySketch"
      handleSubmit={handleSubmit}
    >
      <input type="hidden" name="file_id" ref={register()} />
      <FileInput
        onUploadSuccess={file => setValue("file_id", file.id)}
        url="/sketches/upload"
      />
    </ResourceCreate>
    </PageContent>
  );
};

export default UserCreate;
