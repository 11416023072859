import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import resource from './resource/reducer'

const initialState = {}

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

export const initStore = (preloadedState = initialState) => {
  return createStore(
    combineReducers({
      resource,
    }),
    preloadedState,
    bindMiddleware([thunkMiddleware]),
  )
}
