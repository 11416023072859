import React from "react"
import { makeStyles } from "goodmap-core"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        width: "100%"
    }
}))

const PageContent = ({
    children
}) => {
    const classes = useStyles()

    return <div className={classes.root}>
        {children}
    </div>
}

export default PageContent