import React from "react";
import { ResourceList } from "goodmap-core";
import { useGoodmapContext, useNavigate} from "goodmap-core";
import { MdVisibility } from "react-icons/md";
import {
  setPageSize,
  setPageNumber,
  setSort
} from "../_store/resource/action";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "_components/PageContent";

const SurveySketches = () => {
  const config = useGoodmapContext();
  const dispatch = useDispatch();
  const filter = useSelector(state => state.resource["surveySketch"].filter);
  const navigate = useNavigate()

  return (
    <PageContent>
    <ResourceList
      api={config.API_URL}
      filter={filter}
      setPageSize={e => dispatch(setPageSize("resource", e.target.value))}
      onCreateActionClick={() => navigate(`/surveySketches/create`)}
      setPageNumber={page => dispatch(setPageNumber("dataset", page))}
      setSort={(column, order) =>
        dispatch(setSort("dataset", { column, order }))
      }
      actionButtons={item => [
        <MdVisibility
          tooltip="Zobraziť"
          key="show"
          onClick={() =>
            navigate(`/surveySketches/${item.id}`)
          }
          size="1em"
        />
      ]}
      onItemClick={item =>
        navigate(`/surveySketches/${item.id}`)
      }
      columns={[
        {
          name: "zpmz",
          title: "ZPMZ"
        },
        {
          name: "gpc",
          title: "GPC"
        }
      ]}
      resource="sketch"
    />
    </PageContent>
  );
};

export default SurveySketches;
