import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { AdminLayout, createHistory } from 'goodmap-core'
import IconProfile from '@material-ui/icons/AccountBoxOutlined'
import IconAccount from '@material-ui/icons/AccountBalanceOutlined' //
import IconDashboard from '@material-ui/icons/DashboardOutlined'
import IconSettings from '@material-ui/icons/SettingsOutlined'
import { makeStyles } from "goodmap-core"
import { Layers, LayersOutlined, List, MapOutlined } from '@material-ui/icons'
// import Logo from "../../_components/BaseLogo"



const useStyles = makeStyles((theme) => ({
  root: {

  },
  logo: {
    height: 30,
    width: 30
  }
}))

const DashboardLayout = () => {

  const classes = useStyles();
  const navigate = useNavigate()
  const history = createHistory()
  const itemsCore = [
    {
      name: "Nástenka",
      link: '/',
      Icon: IconDashboard,
    },
    {
      name: "Konfigurácia",
      link: '/configs',
      Icon: IconSettings,
      IconClassName: classes.iconFeatures,
    },
    {
      name: "Vrstvy",
      link: '/layers',
      Icon: LayersOutlined,
      IconClassName: classes.iconDocs,
    },
    {
      name: "Import katastra",
      link: '/cadastreImports',
      Icon: MapOutlined,
      IconClassName: classes.iconSupporters,
    },
    {
      name: "Geometrické plány",
      link: '/surveySketches',
      Icon: MapOutlined,
      IconClassName: classes.iconDiscuss,
    },
    {
      name: "Dataset",
      link: '/datasets',
      Icon: List,
      IconClassName: classes.iconDiscuss,
    },
    {
      name: "Dataset configs",
      link: '/datasetConfigs',
      Icon: IconSettings,
      IconClassName: classes.iconDiscuss,
    }
  
  ]
  
  const itemsTheme = []

  const profileItems = [{
      title: "Profil",
      icon: <IconProfile/>,
      path: "/profile"
    },{
      title: "Nastavenia",
      icon: <IconSettings/>,
      path: "/settings"
    },
  ]

  return (
    <AdminLayout 
      profileItems={profileItems}
      onChangeTenant={(tenant) => navigate("/")}
      sidebarProps={{
        firstSectionTitle: "Systém",
        secondSectionTitle: "Kofigurácia",
        logo: <img src="/logo-goodmap.png" isInversed={true} className={classes.logo} />,
        title: "GoodAdmin",
        tagline: "GoodAdmin"
      }}
      username="Administrátor"
      footerProps={{
        left: "",
        center: "",
        right: ""
      }}
      itemsCore={itemsCore} 
      itemsTheme={itemsTheme}
    >
      <Outlet />
    </AdminLayout>
  )
}

export default DashboardLayout
