import { TextField, Button } from "goodmap-core";
import { JSONEditor } from "react-json-editor-viewer";
import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "goodmap-core";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 32
  },
  button: {
    marginBottom: 16
  }
}));

const JsonInput = ({ inputRef, name, error, onChange, defaultValue }) => {
  const classes = useStyles();
  const [showPlainText, setShowPlainText] = useState(false);
  const plainTextRef = useRef(null);
  const [value, setValue] = useState(defaultValue);

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={() => setShowPlainText(!showPlainText)}
      >
        {showPlainText ? "Upraviť ako JSON" : "Upraviť ako text"}
      </Button>
      <input type="hidden" value={value} name={name} ref={inputRef} />
      {showPlainText ? (
        <>
          <TextField
            required
            margin="normal"
            multiline
            fullWidth
            name={name}
            error={error}
            label="Konfigurácia"
            inputRef={plainTextRef}
            variant="outlined"
            defaultValue={
              value && JSON.stringify(JSON.parse(value), undefined, 2)
            }
          />
          <Button
            variant="outlined"
            onClick={() => {
              onChange(plainTextRef.current.value);
              setValue(plainTextRef.current.value);
              setShowPlainText(false);
            }}
          >
            Potvrdiť
          </Button>
        </>
      ) : (
        <JSONEditor
          data={value && JSON.parse(value)}
          collapsible
          onChange={(key, v, parent, d) => {
            onChange(JSON.stringify(d, undefined, 2));
            setValue(JSON.stringify(d, undefined, 2));
          }}
        />
      )}
    </div>
  );
};

JsonInput.propTypes = {
  inputRef: PropTypes.any,
  name: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default JsonInput;
