import React from "react";
import { ResourceList, useNavigate, useApi } from "goodmap-core";
import { MdEdit, MdRefresh } from "react-icons/md";
import { setPageSize, setPageNumber, setSort } from "../_store/resource/action";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "_components/PageContent";

const Configs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, fetch] = useApi({}, {manual: true});
  const filter = useSelector(state => state.resource["config"].filter);

  return (
    <PageContent>
      <ResourceList
        filter={filter}
        setPageSize={e => dispatch(setPageSize("resource", e.target.value))}
        onCreateActionClick={() => navigate(`/configs/create`)}
        setPageNumber={page => dispatch(setPageNumber("config", page))}
        setSort={(column, order) => dispatch(setSort("config", { column, order }))}
        actionButtons={item => [
          <MdEdit
            tooltip="Upraviť"
            key="edit"
            onClick={() =>  navigate(`/configs/${item.id}/edit`)}
            size="1em"
          />,
          <MdRefresh
            tooltip="Generovať tému"
            key="theme"
            onClick={() => fetch({url: `/configs/${item.id}/generate`})}
            size="1em"
          />
        ]}
        onItemClick={item => navigate(`/configs/${item.id}/edit`)}
        columns={[{
            name: "id",
            title: "ID"
          }, {
            name: "title",
            title: "Názov"
          }, {
            name: "tenant_id",
            title: "Tenant"
          }, {
            name: "role",
            title: "Skupina"
          }
        ]}
        resource="config"
      />
    </PageContent>
  );
};

export default Configs;
