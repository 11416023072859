import React from "react";
import { makeStyles, Typography, useNavigate, useParams, ResourceEdit } from "goodmap-core";
import { useGoodmapContext } from "goodmap-core";
import { useForm } from "react-hook-form";
import PageContent from "_components/PageContent";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16
  },
  field: {
    marginRight: 16
  }
}));

const DatasetEdit = () => {
  const classes = useStyles();
  const { handleSubmit, register, errors, setValue, getValues } = useForm();
  const config = useGoodmapContext();
  const navigate = useNavigate();
  const {id} = useParams()

  return id ? (
    <PageContent>
      <ResourceEdit
        onListClick={() => navigate("/datasets")}
        onSuccess={() => navigate("/datasets")}
        api={config.API_URL}
        handleSubmit={handleSubmit}
        resource="dataset"
        id={id}
      >
        {(data = {}) => {
          return <div></div>;
        }}
      </ResourceEdit>
    </PageContent>
  ) : null;
};

export default DatasetEdit;
