import React from "react";
import { ResourceList } from "goodmap-core";
import { useGoodmapContext, useNavigate } from "goodmap-core";
import {
  setPageSize,
  setPageNumber,
  setSort
} from "../_store/resource/action";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "_components/PageContent";

const DatasetConfigs = () => {
  const config = useGoodmapContext();
  const dispatch = useDispatch();
  const filter = useSelector(state => state.resource["datasetConfig"].filter);
  const navigate = useNavigate()

  return (
    <PageContent>
    <ResourceList
      api={config.API_URL}
      filter={filter}
      setPageSize={e => dispatch(setPageSize("resource", e.target.value))}
      onCreateActionClick={() => navigate(`/datasetConfigs/create`)}
      setPageNumber={page => dispatch(setPageNumber("datasetConfig", page))}
      setSort={(column, order) =>
        dispatch(setSort("datasetConfig", { column, order }))
      }
      onItemClick={item =>
        navigate(
          `/datasetConfigs/${item.id}/edit`
        )
      }
      columns={[
        {
          name: "name",
          title: "Názov"
        },
        {
          name: "tenant_id",
          title: "Tenant"
        },
        {
          name: "roles",
          format: item => item.roles && item.roles.map(x => x.title).join(", "),
          title: "Skupiny"
        }
      ]}
      resource="datasetConfig"
    />
    </PageContent>
  );
};

export default DatasetConfigs;
