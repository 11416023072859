import React, { useEffect } from "react";
import {
  TextField,
  makeStyles,
  MenuItem,
  ResourceEdit,
  useParams,
  useNavigate
} from "goodmap-core";
import { useGoodmapContext } from "goodmap-core";
import { useForm } from "react-hook-form";
import JsonInput from "../_components/JsonInput";
import PageContent from "_components/PageContent";
import { Grid } from "@material-ui/core";
import ClientRolesWidget from "_components/ClientRolesWidget";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16
  },
  field: {
    marginRight: 16
  }
}));

const LayerEdit = () => {
  const classes = useStyles();
  const { handleSubmit, register, errors, setValue } = useForm();
  const config = useGoodmapContext();
  const {id} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    register({ name: "type" }, { required: true });
    register({ name: "roles" })
    register({ name: "client" })
  }, [register]);


  return id ? (
    <PageContent>
    <ResourceEdit
      onListClick={() => navigate("/layers")}
      onSuccess={() => navigate("/layers")}
      api={config.API_URL}
      handleSubmit={handleSubmit}
      resource="layer"
      id={id}
    >
      {(data = {}) => {
        setValue("type", data.type);
        debugger
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                size="small"
                fullWidth
                className={classes.field}
                name="title"
                error={errors.title}
                defaultValue={data.title}
                label="Názov"
                inputRef={register({ required: "Povinné pole" })}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                size="small"
                fullWidth
                className={classes.field}
                name="description"
                error={errors.description}
                defaultValue={data.description}
                label="Popis"
                inputRef={register({ required: "Povinné pole" })}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <JsonInput
                onChange={value => setValue("properties", value)}
                error={errors.properties}
                name="properties"
                defaultValue={data.properties}
                inputRef={register({ required: "Povinné pole" })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                select
                size="small"
                fullWidth
                className={classes.field}
                name="type"
                error={errors.type}
                defaultValue={data.type}
                onChange={e => setValue("type", e.target.value)}
                label="Typ"
                inputRef={register({ required: "Povinné pole" })}
                variant="outlined"
              >
                <MenuItem value="user_layer">Používateľská vrstva</MenuItem>
                <MenuItem value="system_layer">Systémová vrstva</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <ClientRolesWidget
                roles={data.roles}
                onChangeClient={(clientId) => setValue("client", clientId)}
                onChangeRole={(roles) => setValue("roles", roles)}
                clientId="goodmap"
              />
            </Grid>
            </Grid>
           
        );
      }}
    </ResourceEdit>
    </PageContent>
  ) : null;
};

export default LayerEdit;
