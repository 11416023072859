import React from "react";
import { makeStyles, ResourceShow, useParams, useNavigate } from "goodmap-core";
import { useGoodmapContext } from "goodmap-core";
import PageContent from "_components/PageContent";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16
  },
  field: {
    marginRight: 16
  }
}));

const DataSetShow = () => {
  const config = useGoodmapContext();
  const navigate = useNavigate()
  const {id} = useParams();

  return id ? (
    <PageContent>
    <ResourceShow
      onListClick={() => navigate("/datasets")}
      api={config.API_URL}
      resource="dataset"
      id={id}
    >
      {(data = {}) => {
        return <div>{data.path}</div>;
      }}
    </ResourceShow>
    </PageContent>
  ) : null;
};

export default DataSetShow;
