export default {
  'dashboard.title': 'Nástenka',
  'dashboard.subscriptions': 'Odbery',
  'dashboard.recentsubscriptions': 'Posledné odbery',
  'dashboard.subscription.breakdown': 'Odbery prelomové',
  'organization': 'Organizácia',
  'users': 'Používatelia',
  'plan': 'Plán',
  'date': 'Dátum',
  'go.to.dashboard': 'Vrátiť sa späť na nástenku',
  'navbar.title': 'Multiple listing service',
  'navbar.tagline': 'www.renode.sk',
  'navbar.coremodules': 'Hlavné moduly',
  'navbar.dashboard': 'Nástenka',
  'navbar.auth': 'Autentifikácia',
  'navbar.login': 'Prihlásiť sa',
  'navbar.signup': 'Zaregistrovať sa',
  'navbar.recover': 'Obnoviť konto',
  'navbar.reset': 'Resetovať heslo',
  'navbar.account': 'Používatelia',
  'navbar.administration': 'Administrácia',
  'navbar.search': 'Vyhľadávanie',
  'navbar.settings': 'Nastavenia',
  'navbar.error': 'Chyby',
  'navbar.misc': 'Ostatné',
  'navbar.modular': 'Modularita',
  'navbar.docs': 'Dokumnetácia',
  'navbar.supporters': 'Podporovatelia',
  'navbar.discuss': 'Diskusia',
  'navbar.profile': 'Profil',
  'navbar.organization': 'Organizácia',
  'navbar.organizations': 'Organizácie',
  'navbar.users': 'Používatelia',
  'viewcode': 'Pozrieť kód',
  'download': 'Stiahnuť',
  rate: 'Hodnotiť',
  new: 'Nový',
  myprofile: 'Môj profil',
  myaccount: 'Moje konto',
  settings: 'Nastavenia',
  logout: 'Odhlásiť',
  firstname: 'Prihlasovacie meno',
  password: 'Heslo',
  rememberme: 'Zapamätať si ma',
  signin: 'Prihlásiť',
  'forgot.password': 'Zabudol som heslo',
  'dont.have.account': 'Ešte nemám konto',
  email: 'E-mail',
  'password.reset': 'Resetovať heslo',
  'back.to.login': 'Späť na prihlásenie',
  'create.account': 'Vytvoriť konto',
  lastname: 'Priezvisko',
  'receive.marketing.info': 'Chcem dostávať marketingové informácie.',
  'confirm.password': 'Potvrdiť heslo',
  'already.have.account': 'Už mám konto',
  'recover.password': 'Obnoviť heslo',
  placeholder: 'placeholder',
  cancel: 'Zrušiť',
  'extra.hints': 'Extra tipy',
  search: 'Vyhľadávať',
  starproject: 'Ohodnotiť',
  viewreleases: 'Vydania',
  viewall: 'Zobraziť všetko',
  moreactions: 'Viac akcií',
  filter: 'Filter',
  createnew: 'Vytvoriť nové',
  daterange: 'Dátum',
}
