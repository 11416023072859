import React, { useEffect } from "react";
import {
  TextField,
  makeStyles,
  MuiAppBar,
  ReferenceInput,
  Tabs,
  Tab,
  Grid,
  ResourceEdit
} from "goodmap-core";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "goodmap-core";
import { useForm } from "react-hook-form";
import JsonInput from "../_components/JsonInput";
import PageContent from "_components/PageContent";
import MdGenerated from "@material-ui/icons/AutorenewOutlined"
import MdTheme from "@material-ui/icons/MapOutlined"
import MdInfo from "@material-ui/icons/InfoOutlined"
import MdDesktop from "@material-ui/icons/DesktopMacOutlined"
import MdMobil from "@material-ui/icons/MobileFriendlyOutlined"
import MdEdit from "@material-ui/icons/Edit"
import MdServer from "@material-ui/icons/StorageOutlined"
import ClientRolesWidget from "_components/ClientRolesWidget";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  field: {
    marginRight: 16
  },
  tab: {
    minWidth: "auto"
  },
  tabs: { 
    backgroundColor: theme.palette.common.white,
    textColor: theme.palette.primary.main
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ padding: 16, paddingTop: 32, paddingBottom: 100 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const ConfigEdit = () => {
  const classes = useStyles();
  const { handleSubmit, register, errors, setValue } = useForm();
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    register({
      name: "roles"
    })

    register({
      name: "tenant"
    })

    register({
      name: "client"
    })
  }, [])
  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return id ? (
    <PageContent>
      <ResourceEdit
        classes={{root: classes.root}}
        onListClick={() => navigate("/configs")}
        onSuccess={() => navigate("/configs")}
        handleSubmit={handleSubmit}
        resource="config"
        id={id}
      >
        {(data = {}) => {
          
          console.log(data)
          
          return (
            <>
              <MuiAppBar position="static">
                <Tabs
                  value={tab}
                  classes={{root: classes.tabs}}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="simple tabs example"
                >
                  <Tab
                    icon={<MdInfo />}
                    className={classes.tab}
                    label="Všeobecné"
                    {...a11yProps(0)}
                  />
                  <Tab icon={<MdMobil />} className={classes.tab} label="Mobil" {...a11yProps(1)} />
                  <Tab
                    icon={<MdDesktop />}
                    className={classes.tab}
                    label="Desktop"
                    {...a11yProps(2)}
                  />
                  <Tab
                    icon={<MdEdit />}
                    className={classes.tab}
                    label="Editácia"
                    {...a11yProps(3)}
                  />
                  <Tab icon={<MdServer />} className={classes.tab} label="Server" {...a11yProps(4)} />
                  <Tab icon={<MdTheme />} className={classes.tab} label="Témy" {...a11yProps(5)} />
                  <Tab
                    icon={<MdGenerated />}
                    className={classes.tab}
                    label="Vygenerované"
                    {...a11yProps(6)}
                  />
                </Tabs>
              </MuiAppBar>
              <TabPanel value={tab} index={0}>
                <Grid spacing={2} container>
                  <Grid item sm={6} md={6}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      margin="none"
                      className={classes.field}
                      name="title"
                      defaultValue={data.title}
                      error={errors.title}
                      label="Názov"
                      inputRef={register({ required: "Povinné pole" })}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <TextField
                      required
                      margin="none"
                      fullWidth
                      size="small"
                      className={classes.field}
                      defaultValue={data.description}
                      name="description"
                      error={errors.description}
                      label="Popis"
                      inputRef={register({ required: "Povinné pole" })}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <ClientRolesWidget
                      clientId="goodmap"
                      roles={data.roles}
                      onChangeClient={(clientId) => setValue("client", clientId)}
                      onChangeRole={(roles) => setValue("roles", roles)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                      <JsonInput
                      onChange={value => setValue("general", value)}
                      defaultValue={data.general}
                      error={errors.general}
                      name="general"
                      inputRef={register({ required: "Povinné pole" })}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <JsonInput
                  onChange={value => setValue("mobile_config", value)}
                  defaultValue={data.mobile_config}
                  error={errors.mobile_config}
                  name="mobile_config"
                  inputRef={register}
                />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <JsonInput
                  onChange={value => setValue("desktop_config", value)}
                  defaultValue={data.desktop_config}
                  error={errors.desktop_config}
                  name="desktop_config"
                  inputRef={register({ required: "Povinné pole" })}
                />
              </TabPanel>
              <TabPanel value={tab} index={3}>
                <JsonInput
                  onChange={value => setValue("editation", value)}
                  defaultValue={data.editation}
                  error={errors.editation}
                  name="editation"
                  inputRef={register}
                />
              </TabPanel>
              <TabPanel value={tab} index={4}>
                <JsonInput
                  onChange={value => setValue("server_config", value)}
                  defaultValue={data.server_config}
                  error={errors.server_config}
                  name="server_config"
                  inputRef={register}
                />
              </TabPanel>
              <TabPanel value={tab} index={5}>
                <JsonInput
                  onChange={value => setValue("theme_config", value)}
                  defaultValue={data.theme_config}
                  error={errors.theme_config}
                  name="theme_config"
                  inputRef={register({ required: "Povinné pole" })}
                />
              </TabPanel>
              <TabPanel value={tab} index={6}>
                <JsonInput
                  // onChange={value => setValue("config", value)}
                  defaultValue={data.config}
                  // error={errors.config}
                  name="config"
                  // inputRef={register}
                />
              </TabPanel>
            </>
          )}}
      </ResourceEdit>
    </PageContent>
  ) : null;
};

export default ConfigEdit;
