import React, { useEffect } from "react";
import {
  TextField,
  makeStyles,
  ReferenceInput,
  useNavigate,
  ResourceCreate,
  Typography
} from "goodmap-core";
import { useGoodmapContext } from "goodmap-core";
import { useForm } from "react-hook-form";
import JsonInput from "../_components/JsonInput";
import PageContent from "_components/PageContent";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    padding: 8
  },
  field: {
    marginRight: 16
  }
}));

const DatasetConfigCreate = () => {
  const classes = useStyles();
  const { handleSubmit, register, setValue, errors } = useForm();
  const config = useGoodmapContext();
  const navigate = useNavigate();

  useEffect(() => {
    register({ name: "roles" })
    register({ name: "client" })
  }, [register]);

  return (
    <PageContent>
      <ResourceCreate
        api={config.API_URL}
        onListClick={() => navigate("/datasetConfigs")}
        onSuccess={() => navigate("/datasetConfigs")}
        resource="datasetConfig"
        handleSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              margin="normal"
              className={classes.field}
              name="name"
              error={errors.name}
              label="Názov"
              inputRef={register({ required: "Povinné pole" })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput
              required
              margin="normal"
              resource="dataset"
              className={classes.field}
              name="dataset_code"
              onChange={e => setValue("dataset_code", e.target.value)}
              error={errors.tenant_id}
              labelColumn="code"
              valueColumn="code"
              label="Dataset z katalológu"
              inputRef={register({ required: "Povinné pole" })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
              <Typography align="center" gutterBottom>
                Pridadenie role
              </Typography>
              <ClientRolesWidget
                // roles={data.roles}
                onChangeClient={(clientId) => setValue("client", clientId)}
                onChangeRole={(roles) => setValue("roles", roles)}
                clientId="goodmap"
              />
          </Grid>
          <Grid item xs={6}>
            <Typography>Konfigurácia</Typography>
          <JsonInput
            onChange={value => setValue("config", value)}
            error={errors.config}
            name="config"
            inputRef={register({ required: "Povinné pole" })}
          />
          </Grid>
        </Grid>
      </ResourceCreate>
    </PageContent>
  );
};

export default DatasetConfigCreate;
