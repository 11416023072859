import React from 'react'
import { IntlProvider } from 'react-intl'
import { Provider as ReduxProvider } from 'react-redux'
import { initStore } from './_store/store'
import { GoodmapProvider } from 'goodmap-core'

const store = initStore()

const Provider = ({ 
  children, 
  theme, 
  messages, 
  language, 
  config, 
  tenant 
}) => {
  return (
    <GoodmapProvider 
      tenant={tenant} 
      config={config} 
      theme={theme}
    >
        <ReduxProvider store={store}>
          <IntlProvider messages={messages[language]} locale={language}>
            {children}
          </IntlProvider>
        </ReduxProvider>
    </GoodmapProvider>
  )
}

export default Provider
