import React from "react";
import { ResourceList, Button } from "goodmap-core";
import { useGoodmapContext, useApi, useNavigate } from "goodmap-core";
import {
  setPageSize,
  setPageNumber,
  setSort
} from "../_store/resource/action";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "_components/PageContent";

const Datasets = () => {
  const config = useGoodmapContext();
  const dispatch = useDispatch();
  const [, fetch] = useApi({}, {manual: true});
  const navigate = useNavigate()
  const filter = useSelector(state => state.resource["config"].filter);

  return (
    <PageContent>
    <ResourceList
      api={config.API_URL}
      filter={filter}
      actions={[
        <Button
          onClick={() => fetch(`/datasets/generate`)}
          style={{ marginRight: 8 }}
          key="generate"
          variant="outlined"
        >
          Pregenerovať vrstvy
        </Button>
      ]}
      setPageSize={e => dispatch(setPageSize("resource", e.target.value))}
      onCreateActionClick={() => navigate(`/datasets/create`)}
      setPageNumber={page => dispatch(setPageNumber("dataset", page))}
      setSort={(column, order) =>
        dispatch(setSort("dataset", { column, order }))
      }
      onItemClick={item =>
        navigate(`/datasets/${item.id}/show`)
      }
      columns={[
        {
          name: "theme_name",
          title: "Názov témy"
        },
        {
          name: "path",
          title: "Cesta"
        },
        {
          name: "code",
          title: "Kód"
        },
        {
          name: "roles",
          format: item => item.roles && item.roles.map(x => x.title).join(", "),
          title: "Skupiny"
        }
      ]}
      resource="dataset"
    />
    </PageContent>
  );
};

export default Datasets;
