import React from "react";
import { useGoodmapContext, useNavigate, useParams } from "goodmap-core";
import { useForm } from "react-hook-form";
import { makeStyles, ResourceEdit } from "goodmap-core";
import PageContent from "_components/PageContent";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16
  },
  field: {
    marginRight: 16
  }
}));

const SurveySketchEdit = () => {
  const classes = useStyles();
  const { handleSubmit, register, errors, setValue, getValues } = useForm();
  const config = useGoodmapContext();
  const navigate = useNavigate();
  const {id} = useParams();

  return id ? (
    <PageContent>
      <ResourceEdit
        onListClick={() => navigate("/surveySketches")}
        onSuccess={() => navigate("/surveySketches")}
        api={config.API_URL}
        handleSubmit={handleSubmit}
        resource="sketch"
        id={id}
      >
        {(data = {}) => {
          return <div>test</div>;
        }}
      </ResourceEdit>
    </PageContent>
  ) : null;
};

export default SurveySketchEdit;
