import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

const Administration = ({ match }) => {
  const { formatMessage } = useIntl()

  return <div>{formatMessage({ id: 'hello.admin' })}</div>
}

Administration.propTypes = {}

export default Administration
