import React from 'react'
import DashboardLayout from '_layouts/DashboardLayout'
import { Administration } from './Administration'
import Dashboard from './Dashboard'
import { NotFound } from 'Error'
import Configs from 'Configuration'
import ConfigEdit from 'Configuration/edit'
import ConfigCreate from 'Configuration/create'
import Datasets from 'Dataset'
import DatasetEdit from 'Dataset/edit'
import DatasetShow from 'Dataset/show'
import DatasetCreate from 'Dataset/create'
import CadastreImports from 'CadastreImport'
import CadastreImportEdit from 'CadastreImport/edit'
import CadastreImportCreate from 'CadastreImport/create'
import DatasetConfigs from 'DatasetConfig'
import DatasetConfigEdit from 'DatasetConfig/edit'
import DatasetConfigCreate from 'DatasetConfig/create'
import Layers from 'Layer'
import LayerEdit from 'Layer/edit'
import LayerCreate from 'Layer/create'
import SurveySketches from 'SurveySketch'
import SurveySketchEdit from 'SurveySketch/edit'
import SurveySketchCreate from 'SurveySketch/create'

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/surveySketches', element: <SurveySketches /> },
      { path: '/surveySketches/:id/edit', element: <SurveySketchEdit /> },
      { path: '/surveySketches/create', element: <SurveySketchCreate /> },
      { path: '/layers', element: <Layers /> },
      { path: '/layers/:id/edit', element: <LayerEdit /> },
      { path: '/layers/create', element: <LayerCreate /> },
      { path: '/datasets', element: <Datasets /> },
      { path: '/datasets/:id/edit', element: <DatasetEdit /> },
      { path: '/datasets/:id/show', element: <DatasetShow /> },
      { path: '/datasets/create', element: <DatasetCreate /> },
      { path: '/datasetConfigs', element: <DatasetConfigs /> },
      { path: '/datasetConfigs/:id/edit', element: <DatasetConfigEdit /> },
      { path: '/datasetConfigs/create', element: <DatasetConfigCreate /> },
      { path: '/cadastreImports', element: <CadastreImports /> },
      { path: '/cadastreImports/:id/edit', element: <CadastreImportEdit /> },
      { path: '/cadastreImports/create', element: <CadastreImportCreate /> },
      { path: '/configs', element: <Configs /> },
      { path: '/configs/:id/edit', element: <ConfigEdit /> },
      { path: '/configs/create', element: <ConfigCreate /> },
      { path: 'administration', element: <Administration /> },
      { path: '/', element: <Dashboard /> }
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export default routes
