import { actionTypes } from './action'

const resourceState = {
  user: {
    filter: {
      size: 20,
      page: 1,
      sort: 'id,asc',
    },
  },
  role: {
    filter: {
      size: 20,
      page: 1,
      sort: 'id,asc',
    },
  },
  tenant: {
    filter: {
      size: 20,
      page: 1,
      sort: 'id,asc',
    },
  },
  client: {
    filter: {
      size: 20,
      page: 1,
      sort: "id,asc"
    }
  },
  config: {
    filter: {
      size: 20,
      page: 1,
      sort: "id,asc"
    }
  },
  cadastreImport: {
    filter: {
      size: 20,
      page: 1,
      sort: "id,asc"
    }
  },
  dataset: {
    filter: {
      size: 20,
      page: 1,
      sort: "id,asc"
    }
  },
  datasetConfig: {
    filter: {
      size: 20,
      page: 1,
      sort: "id,asc"
    }
  },
  surveySketch: {
    filter: {
      size: 20,
      page: 1,
      sort: "id,asc"
    }
  },
  layer: {
    filter: {
      size: 20,
      page: 1,
      sort: "id,asc"
    }
  }
}

const reducers = (state = resourceState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGE_NUMBER:
      return {
        ...state,
        [action.resource]: {
          ...state.filter,
          page: action.page,
        },
      }
    case actionTypes.SET_PAGE_SIZE:
      return {
        ...state,
        [action.resource]: {
          ...state.filter,
          size: action.size,
        },
      }
    case actionTypes.SET_SORT:
      return {
        ...state,
        [action.resource]: {
          ...state.filter,
          sort: action.sort,
        },
      }
    default:
      return state
  }
}

export default reducers
