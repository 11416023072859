import React, { useState } from "react";
import { TextField, makeStyles, FileInput, ResourceEdit, useNavigate } from "goodmap-core";
import { useGoodmapContext } from "goodmap-core";
import { useForm } from "react-hook-form";
import PageContent from "_components/PageContent";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16
  },
  field: {
    marginRight: 16
  }
}));

const CadastreImportEdit = () => {
  const classes = useStyles();
  const config = useGoodmapContext();
  const { handleSubmit, register, errors, setValue } = useForm();
  const navigate = useNavigate()
  const {id} = useParams()

  return (
    <PageContent>
      {id && (
        <ResourceEdit
          onListClick={() => navigate("/cadastreImports")}
          api={config.API_URL}
          onSuccess={() => navigate("/cadastreImports")}
          handleSubmit={handleSubmit}
          resource="cadastreImport"
          id={id}
        >
          {(data = {}) => (
            <div>
              <TextField
                required
                margin="normal"
                InputLabelProps={{ shrink: true }}
                className={classes.field}
                name="date"
                type="date"
                defaultValue={data.date}
                error={errors.date}
                label="Dátum"
                inputRef={register({ required: "Povinné pole" })}
                variant="outlined"
              />
              <input
                value={data.file_id}
                type="hidden"
                name="file_id"
                ref={register()}
              />
              <FileInput
                onUploadSuccess={file => setValue("file_id", file.id)}
                url="/cadastre/upload"
              />
            </div>
          )}
        </ResourceEdit>
      )}
    </PageContent>
  );
};

export default CadastreImportEdit;
