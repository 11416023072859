import React from "react";
import { ResourceList, useNavigate } from "goodmap-core";
import { useGoodmapContext } from "goodmap-core";
import { MdDelete, MdClear, MdImportExport, MdRefresh } from "react-icons/md";
import { setPageSize, setPageNumber, setSort } from "../_store/resource/action";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "_components/PageContent";

const CadastreImports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useGoodmapContext();
  const filter = useSelector(state => state.resource["cadastreImport"].filter);

  return (
    <PageContent>
    <ResourceList
      filter={filter}
      api={config.API_URL}
      setPageSize={e => dispatch(setPageSize("resource", e.target.value))}
      onCreateActionClick={() => navigate(`/cadastreImports/create`)}
      setPageNumber={page => dispatch(setPageNumber("cadastreImport", page))}
      setSort={(column, order) => dispatch(setSort("cadastreImport", { column, order }))}
      actionButtons={item => [
        <MdDelete
          tooltip="Odstrániť"
          key="delete"
          onClick={() =>
            fetch(config.API_URL + `/cadastreImports/${item.id}`, {
              method: "delete",
              credentials: "include"
            })
          }
          size="1em"
        />,
        <MdImportExport
          tooltip="Spracovať"
          key="process"
          onClick={() => {
            fetch(config.API_URL + `/cadastreImports/${item.id}/process`, {
              credentials: "include"
            });
          }}
          size="1em"
        />,
        <MdClear
          tooltip="Vyčistiť"
          key="clear"
          onClick={() =>
            fetch(config.API_URL + `/cadastreImports/${item.id}/clear`, {
              credentials: "include"
            })
          }
          size="1em"
        />,
        <MdRefresh
          tooltip="Rollback"
          key="rollback"
          onClick={() =>
            fetch(config.API_URL + `/cadastreImports/${item.id}/rollback`, {
              credentials: "include"
            })
          }
          size="1em"
        />
      ]}
      onItemClick={item =>
        navigate( `/cadastreImports/${item.id}/edit` )
      }
      columns={[
        {
          name: "id",
          title: "ID"
        },
        {
          name: "state",
          title: "Stav"
        },
        {
          name: "process_start",
          title: "Spustenie procesu"
        },
        {
          name: "process_end",
          title: "Ukončenie procesu"
        },
        {
          name: "file_id",
          title: "file_id"
        },
        {
          name: "tenant_id",
          title: "tenant_id"
        },
        {
          name: "date",
          title: "date"
        }
      ]}
      resource="cadastreImport"
    />
    </PageContent>
  );
};

export default CadastreImports;
