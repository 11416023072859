import React, { useEffect } from "react";
import {
  TextField,
  makeStyles,
  MenuItem,
  useNavigate,
  ResourceCreate,
  Typography
} from "goodmap-core";
import { useGoodmapContext } from "goodmap-core";
import { useForm } from "react-hook-form";
import JsonInput from "../_components/JsonInput";
import LayerRoleTransferList from "../_components/LayerRoleTransferList";
import PageContent from "_components/PageContent";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    padding: 8
  },
  field: {
    marginRight: 16
  }
}));

const LayerCreate = () => {
  const classes = useStyles();
  const { handleSubmit, getValues, register, setValue, errors } = useForm({
    defaultValues: { type: "system_layer" }
  });
  const config = useGoodmapContext();
  const navigate = useNavigate()
  useEffect(() => {
    register({ name: "type" }, { required: true });
    register({ name: "roles" })
    register({ name: "client" })
  }, [register]);


  return (
    <PageContent>
      <ResourceCreate
        api={config.API_URL}
        onListClick={() => navigate("/layers")}
        onSuccess={() => navigate("/layers")}
        resource="layer"
        handleSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              size="small"
              className={classes.field}
              name="title"
              error={errors.title}
              label="Názov"
              inputRef={register({ required: "Povinné pole" })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              size="small"
              className={classes.field}
              name="description"
              error={errors.description}
              label="Popis"
              inputRef={register({ required: "Povinné pole" })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <JsonInput
              onChange={value => setValue("properties", value)}
              error={errors.properties}
              name="properties"
              inputRef={register({ required: "Povinné pole" })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              select
              size="small"
              className={classes.field}
              name="type"
              defaultValue="system_layer"
              error={errors.type}
              onChange={e => {
                setValue("type", e.target.value);
              }}
              label="Typ"
              variant="outlined"
            >
              <MenuItem value="user_layer">Používateľská vrstva</MenuItem>
              <MenuItem value="system_layer">Systémová vrstva</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <ClientRolesWidget
              onChangeClient={(clientId) => setValue("client", clientId)}
              onChangeRole={(roles) => setValue("roles", roles)}
              clientId="goodmap"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Theme whitelist</Typography>
            <JsonInput
              onChange={value => setValue("theme_whitelist", value)}
              error={errors.theme_whitelist}
              name="theme_whitelist"
              inputRef={register({ required: "Povinné pole" })}
            />
          </Grid>
        </Grid>
      </ResourceCreate>
    </PageContent>
  );
};

export default LayerCreate;
