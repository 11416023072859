import React from 'react'
import routes from './routes'
import Provider from 'Provider'
import locale_en from './_i18n/en'
import locale_sk from './_i18n/sk'
import { HashRouter } from 'goodmap-core'
import theme from './_theme'
import config from './config'

const messages = {
  en: locale_en,
  sk: locale_sk,
}

const language = navigator.language.split(/[-_]/)[0]

export default () => (
  <Provider
    theme={theme}
    language={language}
    config={config}
    messages={messages}
  >
      <HashRouter routes={routes} />
  </Provider>
)
