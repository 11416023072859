import { TransferList } from "goodmap-core";
import { useApi } from "goodmap-core";
import { func, string, any } from "prop-types";
import pluralize from "pluralize";

const LayerRoleTransferList = ({
  resourceId,
  resource,
  handleSelected,
  inputRef
}) => {
  const [{ data: datasetRoles = [], loading: loadingLeft }] = useApi(
    {url: `${pluralize(resource)}/${resourceId}/roles`}
  );
  const [{
    data: availableRoles = { content: [] },
    loading: loadingRight
  }] = useApi({url: `/roles`});

  if (loadingLeft || loadingRight) {
    return null;
  }

  const availableItems = availableRoles.content.filter(function(cItem) {
    return !datasetRoles.find(function(aItem) {
      return cItem.id === aItem.id;
    });
  });

  return (
    <>
      <input type="hidden" name="roles" ref={inputRef} />
      <TransferList
        handleSelected={handleSelected}
        leftItems={datasetRoles}
        rightItems={availableItems}
      />
    </>
  );
};

LayerRoleTransferList.propTypes = {
  resourceId: string,
  inputRef: any,
  handleSelected: func,
  resource: string
};

LayerRoleTransferList.defaultProp = {};

export default LayerRoleTransferList;
