import React, { useEffect } from "react";
import LayerRoleTransferList from "../_components/LayerRoleTransferList";
import {
  makeStyles,
  ReferenceInput,
  TextField,
  Typography,
  ResourceEdit
} from "goodmap-core";
import { useGoodmapContext, useParams, useNavigate } from "goodmap-core";
import { useForm } from "react-hook-form";
import JsonInput from "../_components/JsonInput";
import PageContent from "_components/PageContent";
import { Grid } from "@material-ui/core";
import ClientRolesWidget from "_components/ClientRolesWidget";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16
  },
  field: {
    marginRight: 16
  }
}));

const DatasetConfigEdit = () => {
  const classes = useStyles();
  const { handleSubmit, register, errors, setValue, getValues } = useForm();
  const config = useGoodmapContext();
  const {id} = useParams()
  const navigate = useNavigate();

  useEffect(() => {
    register({ name: "roles" })
    register({ name: "client" })
  }, [register]);

  return id ? (
    <PageContent>
    <ResourceEdit
      onListClick={() => navigate("/datasetConfigs")}
      onSuccess={() => navigate("/datasetConfigs")}
      api={config.API_URL}
      handleSubmit={handleSubmit}
      resource="datasetConfig"
      id={id}
    >
      {(data = {}) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                size="small"
                fullWidth
                className={classes.field}
                name="name"
                defaultValue={data.name}
                error={errors.name}
                label="Názov"
                inputRef={register({ required: "Povinné pole" })}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                required
                size="small"
                fullWidth
                resource="dataset"
                className={classes.field}
                name="dataset_code"
                defaultValue={data.dataset_code}
                onChange={e => setValue("dataset_code", e.target.value)}
                error={errors.dataset_code}
                labelColumn="code"
                valueColumn="code"
                label="Dataset z katalológu"
                inputRef={register({ required: "Povinné pole" })}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography align="center" gutterBottom>
                Pridadenie role
              </Typography>
              <ClientRolesWidget
                roles={data.roles}
                onChangeClient={(clientId) => setValue("client", clientId)}
                onChangeRole={(roles) => setValue("roles", roles)}
                clientId="goodmap"
              />
              {/* <LayerRoleTransferList
                handleSelected={handleSelectedRoles}
                resourceId={data.id}
                resource="DatasetConfig"
                inputRef={register()}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <Typography>Konfigurácia</Typography>
              <JsonInput
                onChange={value => setValue("config", value)}
                error={errors.config}
                defaultValue={data.config}
                name="config"
                inputRef={register({ required: "Povinné pole" })}
              />
            </Grid>
          </Grid>
        );
      }}
    </ResourceEdit>
    </PageContent>
  ) : null;
};

export default DatasetConfigEdit;
