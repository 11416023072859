import React from "react";
import {
  TextField,
  makeStyles,
  ReferenceInput,
  Grid,
  ResourceCreate,
  Typography
} from "goodmap-core";
import { useGoodmapContext, useNavigate } from "goodmap-core";
import { useForm } from "react-hook-form";
import JSONInput from "react-json-editor-ajrm";
import PageContent from "_components/PageContent";

const useStyles = makeStyles(() => ({
  root: {
    padding: 8
  },
  field: {
    marginRight: 16
  }
}));

const DatasetCreate = () => {
  const classes = useStyles();
  const { handleSubmit, register, setValue, errors } = useForm();
  const config = useGoodmapContext();
  const navigate = useNavigate()

  return (
    <PageContent>
    <ResourceCreate
      api={config.API_URL}
      onListClick={() => navigate("/datasets")}
      onSuccess={() => navigate("/datasets")}
      resource="dataset"
      handleSubmit={handleSubmit}
    >
      <TextField
        required
        margin="normal"
        className={classes.field}
        name="title"
        error={errors.name}
        label="Názov"
        inputRef={register({ required: "Povinné pole" })}
        variant="outlined"
      />
      <TextField
        required
        margin="normal"
        className={classes.field}
        name="description"
        error={errors.name}
        label="Popis"
        inputRef={register({ required: "Povinné pole" })}
        variant="outlined"
      />
      <ReferenceInput
        required
        margin="normal"
        resource="role"
        className={classes.field}
        name="role"
        error={errors.role}
        valueColumn="code"
        onChange={e => setValue("role", e.target.value)}
        label="Skupina"
        inputRef={register({ required: "Povinné pole" })}
        variant="outlined"
      />
      <ReferenceInput
        required
        margin="normal"
        resource="tenant"
        className={classes.field}
        name="tenant_id"
        onChange={e => setValue("tenant_id", e.target.value)}
        error={errors.tenant_id}
        valueColumn="id"
        label="Aplikácia"
        inputRef={register({ required: "Povinné pole" })}
        variant="outlined"
      />
      <input
        type="hidden"
        name="config"
        ref={register({ required: "Povinné pole" })}
      />
      <Typography>Konfigurácia</Typography>
      <JSONInput
        id="config"
        onChange={value => setValue("config", value.json)}
        width="100%"
      />
      <input
        type="hidden"
        name="theme_config"
        ref={register({ required: "Povinné pole" })}
      />
      <input type="hidden" name="theme" ref={register()} />
      <Grid container>
        <Grid item sm={6}>
          <Typography>Konfigurácia témy</Typography>
          <JSONInput
            id="theme_config"
            onChange={value => setValue("theme_config", value.json)}
            width="100%"
          />
        </Grid>
        <Grid sm={6}>
          <Typography>Vygenerovaná téma</Typography>
          <JSONInput
            id="theme"
            onChange={value => setValue("theme", value.json)}
            width="100%"
          />
        </Grid>
      </Grid>
    </ResourceCreate>
    </PageContent>
  );
};

export default DatasetCreate;
