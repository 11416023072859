import React, { useEffect, useState } from "react"
import { 
    List, 
    Card, 
    Box,
    Typography, 
    Button,
    TextField, 
    MenuItem, 
    ListItem, 
    useApi, 
    ListItemText, 
    Checkbox,
    ListItemIcon,
    Grid,
    withStyles
} from "goodmap-core";


const ClientList = ({
    onChange,
    defaultValue
}) => {
    const [{data, loading, error}] = useApi({url: "/clients"});
    // const [value, setValue] = useState("")
    const handleOnChange = (e) => {
        // setValue(e.target.value);
        onChange(e.target.value)
    }

    if(loading && error) return null;

    return (
        <TextField 
            size="small" 
            variant="outlined" 
            select
            fullWidth
            defaultValue={defaultValue}
            SelectProps={{displayEmpty: true}}
            onChange={handleOnChange}
            // value={value}
        >
            <MenuItem value="">
                <em> -- Vyberte klienta -- </em>
            </MenuItem>
            {((data && data.content) || []).map(client => {
                return (
                    <MenuItem value={client.id} button key={client.clientId}>
                        <ListItemText
                            primary={client.clientId}
                        />
                    </MenuItem>
                )
            })}
        </TextField>
    )
}

ClientList.defaultProps = {
    onChange: () => {}
}


const ListAvailableClientRoleMappings = ({
    clientId,
    selected,
    handleChange,
    // value
}) => {


    const [{data, loading, error}] = useApi({
        url: `/clients/${clientId}/roles`
    })

    if(loading || error) return null;

    return (
        <RoleMapping
            selected={selected}
            roleMappings={data}
            handleChange={handleChange}
        />
    )
} 


const RoleMapping = ({
    roleMappings,
    selected,
    handleChange
}) => {

    const onChange = (e, role) => {
        handleChange(e.target.checked, role);
    }

    return (
        <List>
            {roleMappings.map(role => 
                    <ListItem button key={role.id}>
                        <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={selected.find(x => x.id === role.id)}
                            tabIndex={-1}
                            onChange={(e) => onChange(e, role)}
                            disableRipple
                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${role.id}` }}
                        />
                        </ListItemIcon>
                        <ListItemText
                            primary={role.name}
                        />
                    </ListItem>
            )}
        </List>
    )
}

RoleMapping.defaultProps = {
    roleMappings: [],
    selected: [],
    handleChange: () => {}
}


const ClientRolesWidget = ({
    clientId: defaultClientId,
    roles: defaultRoles,
    onChangeRole,
    onChangeClient,
    classes
}) => {

    const [clientId, setClientId] = useState()
    const [roles, setRoles] = useState([])

    useEffect(() => {
        defaultRoles && setRoles(defaultRoles)
    }, [defaultRoles])

    // useEffect(() => {
    //     defaultClientId && setClientId(defaultClientId)
    // }, [defaultClientId])

    const handleChangeClient = (clientId) => {
        setClientId(clientId)
        onChangeClient(clientId)
    }

    const handleChangeRole = (checked, role) => {
        if(checked){
            setRoles((state) => {
                onChangeRole([...state, role])
                return [...state, role]
            })
        } else {
            setRoles((state) => {
                const newRoles = state.filter(x => x.id !== role.id);
                onChangeRole(newRoles)
                return newRoles
            })
        }
        
    }
    return (
        <>
            <Card className={classes.card} elevation={1}>
                <Box display="flex" marginBottom={4} alignItems="center">
                    <Typography className={classes.clientsText}>Klienti</Typography>
                    <ClientList defaultValue={defaultClientId} onChange={handleChangeClient}/>
                </Box>
            {
                (clientId) && (
                    <ListAvailableClientRoleMappings 
                        selected={roles}
                        handleChange={handleChangeRole}
                        clientId={clientId} 
                    />
                )
            }
            </Card>
        </>
    )
}

ClientRolesWidget.defaultProps = {
    onChangeRole: () => {},
    onChangeClient: () => {}
}


const styles = (theme) => ({
    actions: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: 50
    },
    card: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        position: "relative",
        minHeight: 300
    },
    clientsText: {
        marginRight: theme.spacing(2)
    }
})

export default withStyles(styles)(ClientRolesWidget)