import React from "react";
import { ResourceList } from "goodmap-core";
import { useGoodmapContext, useNavigate } from "goodmap-core";
import {
  setPageSize,
  setPageNumber,
  setSort
} from "../_store/resource/action";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "_components/PageContent";

const Layers = () => {
  const config = useGoodmapContext();
  const dispatch = useDispatch();
  const filter = useSelector(state => state.resource["layer"].filter);
  const navigate = useNavigate()

  return (
    <PageContent>
      <ResourceList
        api={config.API_URL}
        filter={filter}
        setPageSize={e => dispatch(setPageSize("resource", e.target.value))}
        onCreateActionClick={() => navigate(`/layers/create`)}
        setPageNumber={page => dispatch(setPageNumber("layer", page))}
        setSort={(column, order) => dispatch(setSort("layer", { column, order }))}
        onItemClick={item =>
          navigate(`/layers/${item.id}/edit`)
        }
        columns={[
          {
            name: "title",
            title: "Názov"
          },
          {
            name: "description",
            title: "Popis"
          },
          {
            name: "type",
            title: "Typ"
          }
        ]}
        resource="layer"
      />
    </PageContent>
  );
};

export default Layers;
