export const actionTypes = {
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_SORT: 'SET_SORT',
}

export const setSort = (resource, sort) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_SORT,
    resource,
    sort,
  })
}

export const setPageSize = (resource, size) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_PAGE_SIZE,
    resource,
    size,
  })
}

export const setPageNumber = (resource, page) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_PAGE_NUMBER,
    resource,
    page,
  })
}
